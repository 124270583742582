@import "variables";
@import "global";
@import "scroll";
@import "table";
@import "login";
@import "icons";
@import "button";
@import "modal";
@import "menu";
@import "banner";
@import "card";
@import "img";
@import "utils-text";
@import "utils-color";
@import "utils-size";
@import "utils-border";
@import "utils-spacing";
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root{
  height: 100%;
}
.ant-layout{
  height: 100%;
  .logo{
    height: 32px;
    //line-height: 32px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-family: Arial, Times New Roman;
    //background-image: linear-gradient(135deg, #ff5b18 0%, #ff6161 100%);
    border-radius: 3px;
    cursor: pointer;
    margin: 16px 10px;
  }
  .ant-layout-sider-collapsed{
    .anticon{
      font-size: 16px;
      // margin-left: 8px;
    }
    .nav-text{
      display: none;
    }
    .ant-menu-submenu-vertical > .ant-menu-submenu-title:after{
      display: none;
    }
    .ant-menu-dark:not(.ant-menu-inline) .ant-menu-submenu-open {
      color: inherit;
    }
  }
  p {
    margin: 10px 0 10px 0;
  }
  .global-badge {
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 999;
    background: #1890ff;
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
  }
}
.gutter-example{
  .ant-row{
    //margin-left: 0 !important;
    //margin-right: 0 !important;
    >div{
      background: transparent;
      border: 0;
    }
  }
}
.gutter-box {
  padding: 5px 0;
}

:global {
  .ant-card-head-title {
    font-size: 14px !important;
  }
  .ant-table-pagination.ant-pagination {
    float: none;
  }
}
.global-card {
  min-height: calc(~"100vh - 80px");
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  .loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.global-price {
  color: #d44d44;
  font-weight: 700;
}
.clearfix {
  overflow: auto;
  zoom: 1;
}
ul, ol {
  list-style-type: none;
}